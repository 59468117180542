import React from 'react';

import './App.css';

function App() {
  return (
    <div>
    <div className="w-auto shadow-ls bg-gray-900">
      <div className="w-full md:w-4/6 max-w-screen-lg mx-auto flex flex-row text-gray-200 justify-between pl-4">
  <div className="text-md text-gray-500"><p class="py-4"><span class="text-orange-400 font-bold">Faton.dev</span><br/>Front End Developer <span class="hidden lg:inline-block text-gray-800 italic font-mono"> {' onClick={() => {getName}'}</span></p></div>
       <p class="py-2"><br/>
       <a href="#projects" class="py-2 hidden md:inline-block px-4 rounded-lg hover:text-orange-400 text-gray-500 ml-2">Projects</a>
       <a href="https://www.linkedin.com/in/fatons"  class="inline-block py-2 px-2 rounded-lg hover:text-orange-400 text-gray-500 ml-2">LinkedIn</a>
       <a href="mailto:faton.dev@gmail.com" target="_top" class="inline-block py-2 px-4 rounded-lg hover:text-orange-400 text-gray-500 ml-2">Email</a></p>
       </div>
      </div>
      
 

      <div className="w-full bg-gray-200 p-4">
        <div className="w-full md:w-4/6 shadow-sm mx-auto bg-white border-gray-500 border max-w-screen-lg p-6 pb-8 mt-4">
        <h1 class="text-2xl text-gray-800 pt-2 font-bold">Hello <img class="inline" width="25" height="25" src="hand.png"></img></h1>
        <p class="text-xl leading-7 text-gray-800 mt-2">I'm <b>Faton</b> - I'm a <b>Front End Developer</b> from London, UK. <div class="inline-block hidden lg:visible"><br/></div>

I build <b>React</b> and <b>Redux</b> applications with <b>MongoDB</b> or <b>PostgreSQL</b> backend. Recently I have been using the awesome <b>Tailwind CSS</b> library - which is what this site uses.
Learning new frameworks and technologies is my favourite part.
To discuss <b>project ideas</b> you can email me or use the form below.</p>

        </div></div> 
        
        <div className="w-full shadow-ls bg-gray-200 pt-2 pb-6 px-4">
        <div className="w-full mx-auto bg-white border-gray-500 border max-w-screen-lg md:w-4/6 py-6 pl-6">
     <span className="inline-block font-bold w-32 text-gray-800 mr-2">Technologies</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-800 mr-2 mb-2">React</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-800 mr-2">Redux</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-800 mr-2">Express</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-800 mr-2 mb-2">JavaScript ES6</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-800 mr-2">MongoDB</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-800 mr-2">Git</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-800 mr-2">Docker</span>

        <p class="mt-4"><span className="float-left  font-bold text-gray-800 w-24">Skills</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-800 mr-2 mb-2">Responsive Web Design</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-800 mr-2">Design Principles</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-800 mr-2 mb-2">CSS Libraries</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-800 mr-2">UI/UX Concepts</span>
      
        </p>

        </div></div>

        <div id="projects" className="w-full  bg-gray-200 pt-4 pb-4 px-4">
<div lassName="w-full mx-auto max-w-screen-lg md:w-4/6 px-6">
        <p  className="font-bold text-center text-xl  text-blue-900">Projects</p>
        </div></div>

        <div className="w-full bg-gray-200 py-4 pb-16 px-4">
         

        <div class="flex flex-col max-w-screen-md sm:flex-row items-stretch bg-gray-200 h-auto mx-auto md:w-4/6">
  <div class="w-3/5 mb-4 mx-auto sm:m-2 sm:flex-1 text-gray-700 bg-gray-400 p-0 rounded border border-gray-500">
  <div class="text-lg bg-gray-300 font-bold font-mono text-blue-900 p-4 h-24"><a href="https://rlnc5.codesandbox.io/current">Online<br/>Bank App UI</a></div>
        <div class="text-sm md:text-xs h-32 bg-white text-blue-800 p-4 h-24">Made with <b>React</b> and <b>Redux</b>, this UI allows you to transfer funds between accounts and view a dashboard.</div>
  </div>
  <div class="w-3/5 mb-4 mx-auto sm:m-2 sm:flex-1 text-gray-700 bg-gray-400 p-0 rounded border border-gray-500">
  <div class="text-lg bg-gray-300 font-bold font-mono text-blue-900 p-4 h-24"><a href="https://mvoj4.codesandbox.io/
">Spending<br/>Tracker</a></div>
        <div class="text-sm md:text-xs h-32 bg-white text-blue-800 p-4 h-24">Made with React and Redux, this app will track your weekly expenditure allowing to budget easier.</div>
  </div>
  <div class="w-3/5 mb-4 mx-auto sm:m-2 sm:flex-1 text-gray-700 bg-gray-400 p-0 rounded border border-gray-500">
  <div class="text-lg bg-gray-300 font-bold font-mono text-blue-900 p-4 h-24"><a href="http://postitapp.herokuapp.com/">Post-It Note<br/>Taker</a></div>
        <div class="text-sm md:text-xs h-32 bg-white text-blue-800 p-4 h-24">Made with the MERN stack, Mongodb, Express, and React. This app is deployed on Heroku.</div>
  </div>
</div>





        <div class="flex flex-col max-w-screen-md sm:flex-row items-stretch bg-gray-200 h-auto mx-auto md:w-4/6">
  <div class="w-3/5 mb-4 mx-auto sm:m-2 sm:flex-1 text-gray-700 bg-gray-400 p-0 rounded border border-gray-500">
  <div class="text-lg bg-gray-300 font-bold font-mono text-blue-900 p-4 h-24"><a href="https://56olo.codesandbox.io/">Cashpoint<br/>Locator</a></div>
        <div class="text-sm md:text-xs h-32 bg-white text-blue-800 p-4 h-24">Made with the Google Map API and a High Street Open Bank API, search to locate the nearest Cash Point machine.</div>
  </div>
  <div class="w-3/5 mb-4 mx-auto sm:m-2 sm:flex-1 text-gray-700 bg-gray-400 p-0 rounded border border-gray-500">
  <div class="text-lg bg-gray-300 font-bold font-mono text-blue-900 p-4 h-24"><a href="https://uweht.csb.app/">Scottish<br/>Premier league</a></div>
        <div class="text-sm md:text-xs h-32 bg-white text-blue-800 p-4 h-24">Made in Vanilla JS then converted to React. Tables shows Live rankings of the Scottish Football league from an API</div>
  </div>
  <div class="w-3/5 mb-4 mx-auto sm:m-2 sm:flex-1 text-gray-700 bg-gray-400 p-0 rounded border border-gray-500">
  <div class="text-lg bg-gray-300 font-bold font-mono text-blue-900 p-4 h-24"><a href="https://7urh6.codesandbox.io/">Episode<br/>Search</a></div>
        <div class="text-sm sm:text-xs h-32 bg-white text-blue-800 p-4 h-24">Dynamically search the episode titles of two popular TV series via an API.</div>
  </div>
</div>
          
          </div>


          <div class="bg-white py-6">

    <div class="w-4/6 mx-auto rounded">
      <div class="text-2xl font-bold rounded font-semibold px-4 text-blue-800">Contact me</div>
      <div class="text-xl rounded font-light p-4 text-blue-800">
Looking for a Front-End Developer? Feel free to email or use the form below. I'll get back to you as soon as I can.</div>
    </div>

          </div>


          <div class="bg-white px-4 pt-0">

    <div class="w-full max-w-md mx-auto border border-black mt-12 mb-12">
      <div class="bg-gray-400 font-bold p-4 text-blue-900">Send a Message</div>
      <div class="p-4"><p class="text-blue-800">For any query, you can email me or complete this form.</p></div>
      <form class="w-full max-w-lg p-4">
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2" for="grid-first-name">
        Name
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text"/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Email
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder=""/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Message
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" />
     
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      
      <button class="block w-full bg-orange-400 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none font-bold focus:bg-white focus:border-gray-500" id="grid-password" type="button">Submit</button>
 
    </div>
  </div>
  
</form>



    </div>


          </div>

      



    <div className="w-full bg-gray-900 p-6">
        <div className="mx-auto text-center text-gray-400 w-4/5 md:w-1/3 p-0">
        <div class="text-md text-center mx-auto max-w-screen-lg flex flex-row justify-center">
          
          <span class="rounded p-2 hover:text-orange-400"><a href="#projects" class="inline-block py-2 px-4 rounded-lg hover:text-orange-400 text-gray-500 ml-2">Projects</a>
</span>
          <span class="mx-2 rounded p-2 hover:text-orange-400"><a href="https://www.linkedin.com/in/fatons" class="inline-block py-2 px-4 rounded-lg hover:text-orange-400 text-gray-500 ml-2">LinkedIn</a>
</span>
          <span class="rounded p-2 hover:text-orange-400"><a href="mailto:faton.dev@gmail.com" target="_top" class="inline-block py-2 px-4 rounded-lg hover:text-orange-400 text-gray-500 ml-2">Email</a>
</span>

          </div>


        <p class="text-sm text-gray-500 text-center pt-8">Faton © 2020</p>

        </div></div>


    </div>
  );
}

export default App;
